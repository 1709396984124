<template>
  <v-row no-gutters>
    <base-header-bar></base-header-bar>
    <v-col cols="12" class="text-center mt-15">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import logo from '@src/assets/images/logo/logo-white.svg'

export default {
  name: 'topbar-error',
  data() {
    return {
      logo,
      topbar: this.$store.state.topbar,
      color: this.$store.state.layout.colorDefault,
    }
  },
  methods: {},
  components: {},
  mounted() {
    this.$store.commit('hideLayout')
  },
}
</script>

<style></style>
